import Background from "../Global/Background";
import Image from "../Global/Image";
import Link from "../Global/Link";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const GameCard = (props) => {
    const { item, layout } = props;

    return (
        <div className="h-100 w-100 brand-background-white brand-text-black position-relative">
            <Background
                src={item.content.hero?.filename}
                sm="470x0"
                md="470x0"
                lg="470x0"
                className="ratio ratio-16x9 brand-background-black brand-text-white"
            >
                <div className="d-flex justify-content-center align-items-center">
                    {item.content.logo && item.content.logo.filename && (
                        <Image
                            src={item.content.logo?.filename}
                            sm="0x70"
                            md="0x70"
                            lg="0x70"
                            alt={item.content.title && item.content.title}
                        />
                    )}
                </div>
            </Background>
            {layout === "normal" && (
                <div className="p-4">
                    {item.content.title && (
                        <h4 className="brand-font-uppercase brand-font-bold">
                            {item.content.title}
                        </h4>
                    )}
                    {item.content.preview && (
                        <SmartText>{item.content.preview}</SmartText>
                    )}
                </div>
            )}
            <Link to={item} stretched />
        </div>
    );
};

export default GameCard;

GameCard.propTypes = {
    item: PropTypes.object,
    layout: PropTypes.string,
};

GameCard.defaultProps = {
    item: null,
    layout: "normal",
};
