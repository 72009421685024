import GameCard from "../Cards/GameCard";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const SectionGames = (props) => {
    const { doc, layout } = props;

    let games = doc;

    if (doc.items) {
        games = doc.items;
    }

    let margin = "mb";

    if (doc.margin) {
        margin = doc.margin;
    }

    return (
        <section className={`${margin}-4 ${margin}-md-5`}>
            <div className="container">
                {(doc.body || doc.title) && (
                    <div className="brand-background-white p-4 mb-4">
                        <div className="row justify-content-center">
                            <div className="col-12 text-start">
                                {doc.title && (
                                    <h2 className="brand-font-uppercase brand-font-bold">
                                        {doc.title}
                                    </h2>
                                )}
                                {doc.body && (
                                    <SmartText className="brand-text-columns">
                                        {doc.body}
                                    </SmartText>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="row gy-4 justify-content-center">
                    {games.map((node, index) => {
                        const game = node.link;
                        return (
                            <div
                                key={node._uid}
                                className={`${
                                    layout === "simple" ? `col-6` : `col-12`
                                } col-md-4`}
                            >
                                <GameCard item={game} layout={layout} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionGames;

SectionGames.propTypes = {
    doc: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    layout: PropTypes.string,
};

SectionGames.defaultProps = {
    doc: null,
    layout: "normal",
};
